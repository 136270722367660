import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Button, Divider, Input, message, PageHeader, Space, Spin, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { API_URI } from "../../Config";

function Settings() {

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    
    const [inwork, setInwork] = useState(false);
    const [firstPage, setFirstPage] = useState('');
    const [secondPage, setSecondPage] = useState('');
    const [thirdPage, setThirdPage] = useState('');

    
    const [instagramURL, setInstagramURL] = useState('');
    const [youtubeURL, setYoutubeURL] = useState('');
    const [facebookURL, setFacebookURL] = useState('');
    const [twitterURL, setTwitterURL] = useState('');

    useEffect(() => {

        axios.get(API_URI + "settings")
            .then((response: any) => {
                response.data.forEach((setting: any) => {
                    switch (setting.setkey) {
                        case "inwork":
                            setInwork((setting.value == 'true'));
                            break;
                        case "firstPage":
                            setFirstPage(setting.value);
                            break;
                        case "secondPage":
                            setSecondPage(setting.value);
                            break;
                        case "thirdPage":
                            setThirdPage(setting.value);
                            break;
                        case "youtube":
                            setYoutubeURL(setting.value);
                            break;
                        case "instagram":
                            setInstagramURL(setting.value);
                            break;
                        case "twitter":
                            setTwitterURL(setting.value);
                            break;
                        case "facebook":
                            setFacebookURL(setting.value);
                            break;
                    }
                });
                setLoading(false);
            })
            .catch(error => {
                message.error("Fehler beim Laden der Einstellungen");
            })

    }, []);

    function saveSettings() {
        const key = "savemessage";
        setSaving(true);
        
        message.loading({ content: "Speichere Veränderungen...", key});

        axios.post(API_URI + "settings", {inwork, firstPage, secondPage, thirdPage, youtubeURL, twitterURL, facebookURL, instagramURL})
            .then(response => {
                console.log(response);
                message.success({content: "Gespeichert", key, duration: 3});
                setSaving(false);
            })
            .catch(error => {
                message.error({content: "Fehler beim Speichern aufgetreten", key, duration: 3});
                setSaving(false);
            })
    }

    return (
        <PageHeader title="Einstellungen" subTitle="Alle verfügbaren Einstellungen">
            { !loading ?
            <div className="content">
                <div id="allgemeines">
                    <Divider>Allgemeines</Divider>
                    <h3>Wartungsarbeiten</h3>
                    <div style={{width: "50%"}}>
                        <Switch checked={inwork} onClick={(e) => setInwork(!inwork) } />
                        {inwork ? <p style={{ marginTop: 10}}>Die Wartungsarbeiten sind aktiviert. Das bedeutet das aktuell kein Benutzer die Website sehen kann und lediglich eine Informationsanzeige sieht.</p> : false}
                    </div>
                </div>
                <div id="ueberdich">
                    <Divider>Über dich</Divider>
                    <Space direction="vertical" style={{width: "50%"}}>
                        <h3>Erster Text</h3>
                        <div>
                            <TextArea rows={8} value={firstPage} onChange={(e) => setFirstPage(e.target.value)} autoSize={{ minRows: 3, maxRows: 9 }}/>
                        </div>
                        <br />
                        <h3>Zweiter Text</h3>
                        <div>
                            <TextArea rows={8} value={secondPage} onChange={(e) => setSecondPage(e.target.value)} autoSize={{ minRows: 3, maxRows: 9 }}/>
                        </div>
                        <br />
                        <h3>Dritter Text</h3>
                        <div>
                            <TextArea rows={8} value={thirdPage} onChange={(e) => setThirdPage(e.target.value)} autoSize={{ minRows: 3, maxRows: 9 }}/>
                        </div>
                    </Space>
                </div>
                <div id="socials">
                    <Divider>Soziale Netzwerke</Divider>
                    <Space direction="vertical" style={{width: "50%"}}>
                        <h3>Youtube <YoutubeOutlined /></h3>
                        <div>
                            <Input value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} placeholder="https://www.youtube.com/channel/user" />
                        </div>
                        <br />
                        <h3>Twitter <TwitterOutlined /></h3>
                        <div>
                            <Input value={twitterURL} onChange={(e) => setTwitterURL(e.target.value)} placeholder="https://twitter.com/user" />
                        </div>
                        <br />
                        <h3>Facebook <FacebookOutlined /></h3>
                        <div>
                            <Input value={facebookURL} onChange={(e) => setFacebookURL(e.target.value)} placeholder="https://www.facebook.com/user" />
                        </div>
                        <br />
                        <h3>Instagram <InstagramOutlined /></h3>
                        <div>
                            <Input value={instagramURL} onChange={(e) => setInstagramURL(e.target.value)} placeholder="https://twitter.com/user" />
                        </div>
                        <br />
                    </Space>
                </div>
                <Divider />
                <Button type="primary" onClick={saveSettings} loading={saving}>Speichern</Button>
            </div>
            : <Spin />}
        </PageHeader>
    );
}

export default Settings;