import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Popover, Space, Timeline } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URI, MONTHS } from "../../Config";
import { setImages } from "../../redux/slices/Images";
import { selectTimelineEntries, selectTimelines, setTimelines } from "../../redux/slices/Timeline";
import AddTimelineEntry from "./AddTimelineEntry";
import TimelineEntry from "./TimelineEntry";

function Overview() {

    const dispatch = useDispatch();

    const timelines = useSelector(selectTimelines);
    const timelineEntries = useSelector(selectTimelineEntries);

    function deleteTimeline(id: any) {
        const key = "deleteentry";
        message.loading({ content: "Lösche Eintrag...", key});
        axios.patch(API_URI + "timeline", {id})
        .then(response => {
            message.success({ content: "Erfolgreich gelöscht", key});

            dispatch(setTimelines(response.data));
        })
        .catch(error => {
            message.error({ content: "Fehler beim Löschen aufgetreten", key});
        })
    }

    return (
        <Timeline>
                {timelines.map((timeline: any) => {
                    return <Timeline.Item key={timeline.id}>
                        <h3>{MONTHS[parseInt(timeline.date.split('-')[1])]} {timeline.date.split('-')[0]}
                        <Popover
                            title="Eintragsverwaltung"
                            content={
                                <Space>
                                    <Button danger type="primary"
                                        onClick={() => {deleteTimeline(timeline.id);}}
                                    >
                                        Löschen
                                    </Button>
                                </Space>
                            }
                        >
                                <DeleteOutlined style={{ color: "red", marginLeft: 10 }} />
                            </Popover>
                        </h3>
                        <p>{timeline.description}</p>
                        <Space direction="horizontal" wrap={true}>
                            {
                                timelineEntries.filter((e: any) => e.timeline_id == timeline.id).map((entry: any) => {
                                    return <TimelineEntry entry={entry} key={entry.id} />
                                })

                            }
                            <AddTimelineEntry timeline={timeline} />
                        </Space>
                    </Timeline.Item>
                })}
        </Timeline>
    );
}

export default Overview;