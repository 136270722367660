import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Popover, Space, Button, Divider, Image, Input, Collapse, message } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import TextArea from "antd/lib/input/TextArea";
import { randomInt } from "crypto";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API_URI } from "../../Config";
import { selectImages } from "../../redux/slices/Images";
import ImagePreview from "./Images/ImagePreview";

function SingleGallery(props: any) {

    const images = useSelector(selectImages);

    const gallery = props.gallery;
    const deleteGallery = props.deleteGallery;
    const saveGallery = props.saveGallery;

    const [label, setLabel] = useState(gallery.label);
    const [description, setDescription] = useState(gallery.description);

    function save() {
        if(label.length < 3 || label.length > 128) return message.warn("Label ist zu kurz/lang");
        if(description.length > 1024) return message.warn("Beschreibung ist zu lang");

        const g = {
            id: gallery.id,
            label,
            description
        }
        saveGallery(g);
    }

    return (
        
        <Collapse>
            <CollapsePanel
                header={<span><Input value={label} onChange={(e) => setLabel(e.target.value)} style={{display: "inline", width: ((label.length + 3) * 8) + 'px'}} onKeyPress={(e:any) =>  {e.target.style.width = ((e.target.value.length + 3) * 8) + 'px' }} bordered={false} placeholder="Titel" /> <span style={{opacity: ".5", fontSize: "80%", position: "relative", top: "-3px"}}>{gallery.createdAt}</span></span>}
                key={gallery.id}
                extra={
                    <Space>
                        <SaveOutlined
                                onClick={event => {
                                    save();
                                    event.stopPropagation();
                                }}
                        />
                        <Popover
                            content={
                                <Space direction="vertical">
                                    <p>Bist du dir sicher?</p>
                                    <Space>
                                        <Button type="primary" danger onClick={(e) => {
                                            deleteGallery(gallery.id);
                                            e.stopPropagation();
                                        }}>Ganz sicher!</Button>
                                    </Space>
                                </Space>
                            }
                        >
                            <DeleteOutlined style={{color: "red"}}
                                onClick={event => {
                                    event.stopPropagation();
                                }}
                            />
                        </Popover>
                    </Space>
                }
            >
                <Divider orientation="left">Beschreibung</Divider>
                <TextArea
                    value={description}
                    onChange={(e: any) => setDescription(e.target.value)}
                    placeholder="Beschreibung der Galerie"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    bordered={false}
                />
                <Divider orientation="left">Bilder</Divider>
                <Image.PreviewGroup>
                    <Space direction="horizontal" wrap={true}>
                    {
                        images.filter((i: any) => i.gallery_id == gallery.id)
                        .sort(function(a: any, b: any) {return b.primary - a.primary })
                        .map((image: any) => {
                            return (
                                <ImagePreview image={image} key={(image.key + image.gallery_id)} />
                            )
                        })
                    }
                    </Space>
                </Image.PreviewGroup>
            </CollapsePanel>
        </Collapse>
    );
}

export default SingleGallery;