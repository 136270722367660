import { Button, Col, DatePicker, Input, message, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Layout from "antd/lib/layout/layout";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_URI } from "../../Config";
import { setGallerys } from "../../redux/slices/Images";

function AddGallery() {

    const dispatch = useDispatch();

    const [label, setLabel] = useState("");
    const [description, setDescription] = useState("");
    const [createdAt, setCreatedAt] = useState("");

    const [isAdding, setIsAdding] = useState(false);

    function add() {
        if(label.length < 3 || label.length > 128) return message.warn("Label ist zu kurz/lang");
        if(description.length > 1024) return message.warn("Beschreibung ist zu lang");
        if(createdAt.length == 0) return message.warn("Datum ist nicht gesetzt");

        setIsAdding(true);

        axios.put(API_URI + "gallery", {label, description, createdAt})
        .then(response => {
            setIsAdding(false);
            message.success("Galerie " + label + " hinzugefügt");
            setLabel("");
            setDescription("");

            dispatch(setGallerys(response.data));
        })
        .catch(error => {
            message.error("Fehler beim Hinzufügen.")
        })
    }
 
    return (
        <Layout>
            <Row justify="space-between">
            	<Col span={9}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Input type="text" placeholder="Titel der Galerie" value={label} onChange={(e) => setLabel(e.target.value)}/>
                        <DatePicker style={{width: "100%"}} placeholder="Wann war das Shooting?" onChange={(d, ds) => setCreatedAt(ds) }/>
                        <Button type="primary" onClick={add} loading={isAdding}>Erstellen</Button>
                    </Space>
                </Col>
                <Col span={14}>
                    <TextArea rows={8} autoSize={{ minRows: 3, maxRows: 9 }} placeholder="Beschreibung der Galerie" value={description} onChange={(e) => setDescription(e.target.value)}/>
                </Col>
            </Row>
        </Layout>
    )
}

export default AddGallery;