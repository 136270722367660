import { Divider, Layout } from "antd";
import AddImage from "./AddImage";
import Overview from "./Overview";

function Images() {

    return (
        <Layout>
            <Divider>Neues Bild hochladen</Divider>
            <AddImage />
            <Divider>Übersicht aller Bilder</Divider>
            <Overview />
        </Layout>
    );
}

export default Images;