import { Divider, Empty, message, PageHeader, Spin, Timeline } from "antd";
import Layout from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { API_URI } from "../../Config";
import { setTimelineEntries, setTimelines } from "../../redux/slices/Timeline";
import AddEntry from "./AddEntry";
import Overview from "./Overview";

function CustomTimeLine() {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get(API_URI + "timeline")
        .then(response => {
            dispatch(setTimelines(response.data));
            axios.get(API_URI + "timeline_entries")
            .then(response => {
                dispatch(setTimelineEntries(response.data));
                setIsLoading(false);
            })
            .catch(error => {
                message.error("Fehler beim Laden");
            })
        })
        .catch(error => {
            message.error("Fehler beim Laden");
        })
    }, []);

    if(isLoading) return <Spin />
    return (
        <Layout>
            <PageHeader
                title="Zeitlinie"
                subTitle="Organisiere deine Zeitlinie wie es dir passt"
            />
            <Divider>Neuen Eintrag hinzufügen</Divider>
            <AddEntry />
            <Divider>Übersicht der Zeitlinie</Divider>
            <Overview />
        </Layout>
    );
}

export default CustomTimeLine;