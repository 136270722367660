import { UserOutlined, LaptopOutlined, NotificationOutlined, DashboardOutlined, LogoutOutlined, IdcardOutlined, FileImageOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { API_URI } from "../Config";
import { setLoggedIn } from "../redux/slices/Auth";

function SideBar() {

    const dispatch = useDispatch();

    const Location = useLocation();
    const route = Location.pathname;

    useEffect(() => {

    }, [route]);

    function logout() {
      axios.delete(API_URI + "auth");
      dispatch(setLoggedIn(false));
    }

    const logoutMenuItem = <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>Ausloggen</Menu.Item>;

    if(route.includes("/einstellungen")) {
      return (
          <Sider width={200} className="site-layout-background" style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}>
            <Menu
              mode="inline"
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item key="1" icon={<DashboardOutlined />}>
                <Link to="#allgemeines">
                  Allgemeines
                </Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<UserOutlined />}>
                <Link to="#ueberdich">
                  Über dich
                </Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<YoutubeOutlined />}>
                <Link to="#socials">
                  Soziale Netzwerke
                </Link>
              </Menu.Item>
              {logoutMenuItem}
            </Menu>
          </Sider>
      );
    } else if(route.includes("/galerie")) {
      return (
          <Sider width={200} className="site-layout-background" style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}>
            <Menu
              mode="inline"
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item key="2" icon={<FileImageOutlined />}>
                <Link to="/galerie">
                  Galerien
                </Link>
              </Menu.Item>
              <Menu.Item key="1" icon={<IdcardOutlined />}>
                <Link to="/galerie/images">
                  Alle Bilder
                </Link>
              </Menu.Item>
              {logoutMenuItem}
            </Menu>
          </Sider>
      );
    } else {
      return (
          <Sider width={200} className="site-layout-background" style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}>
            <Menu
              mode="vertical"
              style={{ height: 'calc(100% - 64px)', borderRight: 0, alignItems: "flex-end" }}
            >
              {logoutMenuItem}
            </Menu>
          </Sider>
      );
    }

    return (
      <div />
    );
}

export default SideBar;