import { Menu, message, PageHeader, Spin } from 'antd';
import Layout, { Content, Footer, Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { Switch, BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import Start from '../pages/start/Start';
import Settings from '../pages/settings/Settings';
import SideBar from './Sidebar';
import Gallery from '../pages/gallery/Gallery';
import { CameraOutlined, FieldTimeOutlined, FundOutlined, HomeFilled, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import Statistics from '../pages/statistics/Statistics';
import logo from "../Logo.svg";
import NotFound from '../pages/errors/NotFound';
import CustomTimeLine from '../pages/timeline/CustomTimeLine';
import axios from 'axios';
import { API_URI } from '../Config';
import { setGallerys, setImages } from '../redux/slices/Images';
import { useDispatch } from 'react-redux';

function WebLayout() {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const routes = [
        {
          path: '/',
          breadcrumbName: 'Start',
          component: Start
        },
        {
          path: '/statistik',
          breadcrumbName: 'Statistik',
          component: Statistics
        },
        {
          path: '/galerie',
          breadcrumbName: 'Galerie',
          component: Gallery
        },
        {
          path: '/galerie/:id',
          breadcrumbName: 'Galerie',
          component: Gallery
        },
        {
          path: '/zeitlinie',
          breadcrumbName: 'Zeitline',
          component: CustomTimeLine
        },
        {
          path: '/einstellungen',
          breadcrumbName: 'Einstellungen',
          component: Settings
        }
      ];

      useEffect(() => {
        axios.get(API_URI + "gallery")
        .then((response) => {
            dispatch(setGallerys(response.data));

            axios.get(API_URI + "images")
            .then((response) => {
                dispatch(setImages(response.data));
                setLoading(false);
            })
            .catch(() => {
                message.error("Fehler beim Laden der Bilder")
            })
        })
        .catch(() => {
            message.error("Fehler beim Laden der Galerien")
        })
    }, [])
    
    if(loading) return <Spin />
      return (
        <BrowserRouter basename="/backend">
          <Layout style={{minHeight: "100vh"}}>
            <Header className="header"  style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
              <div className="logo" style={{height: "100%"}}>
                <img src={logo} />
              </div>
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="1" icon={<HomeOutlined />}><Link to="/">Start</Link></Menu.Item>
                <Menu.Item key="2" icon={<CameraOutlined />}><Link to="/galerie">Galerie</Link></Menu.Item>
                <Menu.Item key="3" icon={<FundOutlined />}><Link to="/statistik">Statistiken</Link></Menu.Item>
                <Menu.Item key="4" icon={<FieldTimeOutlined />}><Link to="/zeitlinie">Zeitlinie</Link></Menu.Item>
                <Menu.Item key="5" icon={<SettingOutlined />}><Link to="/einstellungen">Einstellungen</Link></Menu.Item>
              </Menu>
            </Header>
            <Layout style={{marginTop: 64}}>
              <SideBar />
              <Layout style={{ padding: '0 24px 24px', marginLeft: 200 }}>
                <Content
                  className="site-layout-background"
                  style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  <Switch>
                    { routes.map(route => {
                      if(route.component != null && route.component != undefined) {
                        return <Route exact key={route.path} path={route.path} component={route.component} />
                      }
                    })}
                    <Route component={NotFound} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </BrowserRouter>
      );
}

export default WebLayout;
