import { combineReducers, configureStore } from "@reduxjs/toolkit";
import Auth from "./slices/Auth";
import Images from "./slices/Images";
import Timeline from "./slices/Timeline";

export const store = configureStore({
    reducer: {
        auth: Auth,
        images: Images,
        timeline: Timeline
    }
});