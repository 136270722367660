import { message, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./App.css";
import { API_URI } from './Config';
import { selectLoggedIn, setLoggedIn } from './redux/slices/Auth';
import LoginLayout from './templates/LoginLayout';
import WebLayout from './templates/WebLayout';

function App() {

  const loggedIn = useSelector(selectLoggedIn);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(API_URI + "auth")
      .then(response => {
        if(response.data == "true") {
          dispatch(setLoggedIn(true));
        }
        setLoading(false);
      })
      .catch(() => {
        message.error("Fehler beim Verbinden zum Server");
      })
  }, [])

  if(!loading) {
    if(loggedIn) {
      return (
        <WebLayout />
      );
    } else {
      return (
        <LoginLayout />
      );
    }
  } else return <Spin />;
}

export default App;
