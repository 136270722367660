import { Button, Descriptions, Divider, Image, message, Popover, Select, Space } from "antd";
import { Option } from "antd/lib/mentions";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URI } from "../../../Config";
import { selectGallerys, selectImages, setImages } from "../../../redux/slices/Images";
import ImagePreview from "./ImagePreview";

function Overview() {

    const images = useSelector(selectImages);
    const gallerys = useSelector(selectGallerys);
    const dispatch = useDispatch();

    return (
        <Image.PreviewGroup>
            <Space direction="horizontal" wrap={true}>
            {
                images.map((image: any) => {
                    return <ImagePreview key={image.id} image={image} />
                })
            }
            </Space>
        </Image.PreviewGroup>
    )
}

export default Overview;