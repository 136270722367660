import { Empty, PageHeader } from "antd";
import Layout from "antd/lib/layout/layout";

function Start() {

    return (
        <PageHeader title="Start" subTitle="Übersicht aller wichtigen Informationen">
            <Empty />
        </PageHeader>
    )
}

export default Start;