import { DeleteOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Collapse, Divider, Image, Layout, message, Modal, Popover, Row, Space } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URI } from "../../Config";
import { selectGallerys, selectImages, setGallerys } from "../../redux/slices/Images";
import SingleGallery from "./SingleGallery";

function Overview() {

    const gallerys = useSelector(selectGallerys);
    const images = useSelector(selectImages);
    const dispatch = useDispatch();

    useEffect(() => {
    }, [gallerys, images]);

    function deleteGallery(id: string) {
        const key = "deletegallery";
        
        message.loading({ content: "Lösche Gallerie...", key});

        axios.patch(API_URI + "gallery", {id})
        .then(response => {
            dispatch(setGallerys(response.data))
            message.success({ content: "Erfolgreich gelöscht", key});
        })
        .catch(() => {
            message.error({ content: "Fehler beim Löschen aufgetreten", key});
        })
    }

    function saveGallery(g: any) {
        const key = "savegallery";
        
        message.loading({ content: "Speichere Veränderungen...", key});

        axios.post(API_URI + "gallery", {id: g.id, label: g.label, description: g.description})
        .then(response => {
            dispatch(setGallerys(response.data))
            message.success({ content: "Erfolgreich gespeichert", key});
        })
        .catch(() => {
            message.error({ content: "Fehler beim Speichern", key});
        })
    }

    return (
        <Layout>
            {
                gallerys.map((gallery: any) => {
                    return (
                        <SingleGallery deleteGallery={deleteGallery} gallery={gallery} saveGallery={saveGallery} key={gallery.id}/>
                    )
                })
            }
        </Layout>
    )
}

export default Overview;