import { Button, Col, DatePicker, Input, Layout, message, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URI } from "../../Config";
import { selectTimelines, setTimelines } from "../../redux/slices/Timeline";

function AddEntry() {

    const timelines = useSelector(selectTimelines);

    const dispatch = useDispatch();
    const [isAdding, setIsAdding] = useState(false);

    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");

    function add() {
        const key = "insertentry";

        if(date.length == 0) return message.warn("Das ausgewählte Datum ist nicht gültig.");
        if(timelines.filter((t: any) => t.date == date).length > 0) return message.warn("Das ausgewählte Datum ist nicht gültig.");
        if(description.length < 5 || description.length > 64) return message.warn("Die eingegebene Beschreibung ist nicht gültig.");

        message.loading({content: "Füge Eintrage hinzu...", key});

        setIsAdding(true);
        axios.put(API_URI + "timeline", {
            description,
            date
        })
        .then(response => {
            message.success({content: "Erfolgreich eingetragen", key});
            dispatch(setTimelines(response.data));
            setIsAdding(false);

            setDescription("");
        })
        .catch(error => {
            message.error({content: "Fehler beim Einfügen", key});
            setIsAdding(false);
        })
    }

    function onDatepickerChange(date: any, dateString: string) {
        setDate(dateString + "-01");
    }

    return (
        <Layout>
            <Row justify="space-between">
            	<Col span={9}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <DatePicker onChange={onDatepickerChange} picker="month" style={{width: "100%"}} placeholder="Wähle einen Monat aus" />
                        <Button type="primary" onClick={add} loading={isAdding}>Erstellen</Button>
                    </Space>
                </Col>
                <Col span={14}>
                    <TextArea rows={8} autoSize={{ minRows: 3, maxRows: 9 }} placeholder="Beschreibung des Eintrages" value={description} onChange={(e: any) =>  setDescription(e.target.value)}/>
                </Col>
            </Row>
        </Layout>  
    );
}

export default AddEntry;