import { createSlice } from "@reduxjs/toolkit";

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: {
        timelines: [],
        timeline_entries: []
    },
    reducers: {
        setTimelines: (state, action) => {
            state.timelines = action.payload;
        },
        setTimelineEntries: (state, action) => {
            state.timeline_entries = action.payload;
        }
    }
});

export const { setTimelines, setTimelineEntries } = timelineSlice.actions;
export const selectTimelines = (state: any) => state.timeline.timelines;
export const selectTimelineEntries = (state: any) => state.timeline.timeline_entries;

export default timelineSlice.reducer;