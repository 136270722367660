import { PageHeader, Empty } from "antd";

function Statistics() {

    return (
        <PageHeader title="Statistik" subTitle="Alle wichtigen Zahlen in einer Übersicht">
            <Empty />
        </PageHeader>
    )
}

export default Statistics;