import { Divider, Empty, message, PageHeader, Space, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { API_URI } from "../../Config";
import { setGallerys, setImages } from "../../redux/slices/Images";
import AddGallery from "./AddGallery";
import Images from "./Images/Images";
import Overview from "./Overview";

type GalleryParams = {
    id: string | undefined
}

function Gallery() {

    const { id } = useParams<GalleryParams>();
    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(API_URI + "gallery")
        .then((response) => {
            dispatch(setGallerys(response.data));

            axios.get(API_URI + "images")
            .then((response) => {
                dispatch(setImages(response.data));
                setLoading(false);
            })
            .catch(() => {
                message.error("Fehler beim Laden der Bilder")
            })
        })
        .catch(() => {
            message.error("Fehler beim Laden der Galerien")
        })
    }, [])
    
    if(loading) {
        return (
            <PageHeader title="Galerie" subTitle="Verwalte ganz entspannt die Bilder, die Dich auszeichnen.">
                <Spin />
            </PageHeader>
        )
    } else {
        return <PageHeader title="Galerie" subTitle="Verwalte ganz entspannt die Bilder, die Dich auszeichnen.">
            { id == "images" ? <Images /> : <Layout>
                <Divider>Neue Galerie anlegen</Divider>
                <AddGallery />
                <Divider>Übersicht aller Galerien</Divider>
                <Overview />
            </Layout> }
        </PageHeader>
    }

}

export default Gallery;