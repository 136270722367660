import { Result, Button } from "antd";
import { Link } from "react-router-dom";

function NotFound() {

    return (
        <Result
            status="404"
            title="404"
            subTitle="Diese Seite existiert nicht."
            extra={<Link to="/"><Button type="primary">Zurück zum Start</Button></Link>}
        />
    );
}

export default NotFound;