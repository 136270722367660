import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URI } from "../../Config";

const imagesSlice = createSlice({
    name: 'authentication',
    initialState: {
        gallerys: [],
        images: []
    },
    reducers: {
        setImages: (state, action) => {
            state.images = action.payload;
        },
        setGallerys: (state, action) => {
            state.gallerys = action.payload;
        }
    }
});

export const { setImages, setGallerys } = imagesSlice.actions;

export const selectImages = (state: any) => state.images.images;
export const selectGallerys = (state: any) => state.images.gallerys;

export default imagesSlice.reducer;