import { FileAddOutlined } from "@ant-design/icons";
import { Modal, Descriptions, Divider, Select, Radio, Space, Input, message } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URI } from "../../Config";
import { selectGallerys } from "../../redux/slices/Images";
import { setTimelineEntries } from "../../redux/slices/Timeline";

function AddTimelineEntry(props: any) {

    const dispatch = useDispatch();

    const timeline = props.timeline;

    const gallerys = useSelector(selectGallerys);

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [type, setType] = useState(0);
    const [videoId, setVideoId] = useState("");
    const [galleryId, setGalleryId] = useState();


    function add() {
        setVisible(true);
    }

    function submitAdd() {
        const key = "insertentry";

        if(type == 0 && !galleryId) return message.warn("Die ausgewählte Galerie ist nicht gültig.");
        if(type == 1 && videoId.length < 5 || type == 1 && videoId.length > 64) return message.warn("Die eingegebene Video Id ist nicht gültig.");

        message.loading({content: "Füge Eintrage hinzu...", key});

        setConfirmLoading(true);
        axios.put(API_URI + "timeline_entries", {
            timeline_id: timeline.id,
            type,
            gallery_id: galleryId,
            video_url: videoId
        })
        .then(response => {
            message.success({content: "Erfolgreich eingetragen", key});
            dispatch(setTimelineEntries(response.data));
            setVisible(false);
            setConfirmLoading(false);
        })
        .catch(error => {
            message.error({content: "Fehler beim Einfügen", key});
            setVisible(false);
            setConfirmLoading(false);
        })
    }

    function submitExit() {
        setConfirmLoading(false);
        setVisible(false);
    }

    return (
        <div>
            <div
                style={{
                    width: 200,
                    height: 200,
                    background: "#ddd",
                    position: "relative",
                    top: "-3px",
                    cursor: "pointer"
                }}
                onClick={add}
            >
                <FileAddOutlined style={{ color: "#888", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", fontSize: "250%" }} />
            </div>
            <Modal
                title="Neuer Zeitlinien Eintrag"
                visible={visible}
                onOk={submitAdd}
                onCancel={submitExit}
                confirmLoading={confirmLoading}
                width={700}
            >
                <Divider>Wähle den Type des Eintrages aus</Divider>
                <Radio.Group
                    value={type}
                    onChange={(e) =>  setType(e.target.value)}
                >
                    <Space direction="vertical">
                        <Radio value={0}>Galerie</Radio>
                        <Radio value={1}>Youtube Id</Radio>
                    </Space>
                </Radio.Group>
                <Divider>Eingabe des Wertes</Divider>
                {
                    type == 0 ?
                        <Select
                            style={{width: "100%"}}
                            value={galleryId}
                            onChange={(e: any) => setGalleryId(e)}
                            placeholder="Wähle eine Galerie aus"
                        >
                            {
                                gallerys.map((gallery:any) => {
                                    return <Select.Option key={gallery.id} value={gallery.id}>
                                        {gallery.label}
                                    </Select.Option>
                                })
                            }
                        </Select>
                        : <div>
                            <p><i style={{opacity: .7}}>https://www.youtube.com/watch?v=</i><b>pqiO8wV4-wc</b></p>
                            <Input value={videoId} onChange={(e:any) => setVideoId(e.target.value)} placeholder="Video Id"  style={{width: "100%"}}/>
                        </div>
                }
            </Modal>
        </div>
    )
}

export default AddTimelineEntry;