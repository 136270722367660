import { SmileOutlined, UploadOutlined } from "@ant-design/icons";
import { Layout, Row, Col, Space, Input, DatePicker, Button, Upload, message, Empty } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_URI } from "../../../Config";
import { setImages } from "../../../redux/slices/Images";

function AddImage() {

    const dispatch = useDispatch();

    const [isAdding, setIsAdding] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);

    function upload() {
        const key = "addimage";
        message.loading({ content: "Lade Bilder hoch...", key});

        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        setIsAdding(true);
        axios.post(API_URI + "images", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
            message.success({ content: "Erfolgreich hochgeladen", key});
            setIsAdding(false);
            setFileList([]);

            dispatch(setImages(response.data));
        })
        .catch(error => {
            message.error({ content: "Fehler beim Hochladen aufgetreten", key});
            setIsAdding(false);
        })
    }

    return (
        <Layout>
            <Row justify="space-between">
            	<Col span={9}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Upload
                            beforeUpload={ (file: any) => { if(!file.type.includes("image")) message.error("Es sind nur Bildformate erlaubt."); else setFileList([...fileList, file]); return false; } }
                            onRemove={(file: any) => { 
                                const index = fileList.indexOf(file);
                                const newFileList = fileList.slice();
                                newFileList.splice(index, 1);
                                setFileList(newFileList);
                            }}
                            fileList={fileList}
                            listType="picture"
                            accept=".jpg,.png,.jpeg"
                            style={{width: "100%"}}
                        >
                            <Button icon={<UploadOutlined />} style={{width: "100%"}}>Hinzufügen</Button>
                        </Upload>
                        { fileList.length == 0 ?
                        <div style={{ textAlign: 'center' }}>
                            <SmileOutlined style={{ fontSize: 20 }} />
                            <p>Es sind noch keine Bilder hinzugefügt worden</p>
                        </div> : <div></div>
                        }
                        <Button type="primary" onClick={upload} loading={isAdding}>Hochladen</Button>
                    </Space>
                </Col>
                <Col span={14}>

                </Col>
            </Row>
        </Layout>
    );
}

export default AddImage;