import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Input, message, Space } from "antd";
import Layout from "antd/lib/layout/layout";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_URI } from "../Config";
import { setLoggedIn } from "../redux/slices/Auth";
import logo from "../Logo.svg";

function LoginLayout() {

    const dispatch = useDispatch();
    const [password, setPassword] = useState("");

    function login() {
        axios.put(API_URI + "auth", {password})
            .then(response => {
                if(response.data == "true") {
                    message.success("Erfolgreich angemeldet");
                    dispatch(setLoggedIn(true));
                } else {
                    message.warn("Falsches Passwort");
                }
            })
            .catch(error => {
                message.error("Fehler beim Einloggen");
            })
    }

    return (
        <Layout style={{minHeight: "100vh", position: "relative"}}>
            <Card title={<img style={{height: 30, margin: "0 auto", filter: "brightness(0)", display: "block"}} src={logo}/>} bordered={true} style={{width: 300, position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <Space direction="vertical">
                    <Input size="large" placeholder="Passwort" prefix={<LockOutlined />} type="password" value={password} onChange={(e) => setPassword(e.target.value) } />
                    <Button type="primary" onClick={login}>Einloggen</Button>
                </Space>
            </Card>
        </Layout>
    );
}

export default LoginLayout;