import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        loggedIn: false
    },
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        }
    }
});

export const { setLoggedIn } = authSlice.actions;
export const selectLoggedIn = (state: any) => state.auth.loggedIn;

export default authSlice.reducer;